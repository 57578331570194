<template>
  <v-card class="mb-5">
    <v-card-text class="pt-1">
      <custom-snackbar ref="snackbar" />

      <v-toolbar flat class="d-flex flex-row-reverse">
        <v-dialog
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  class="mr-3"
                  v-on="on"
                >
                  <v-icon dark>mdi-printer</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="exportFile('PDF')">
                  <v-list-item-title>Exportar PDF</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportFile('Excel')">
                  <v-list-item-title>Exportar Excel</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn color="info" dark outlined v-bind="attrs" v-on="on">
              Adicionar Liberação de Colheita
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-toolbar color="info" dark>
              <span class="headline">{{ formTitle }}</span>
            </v-toolbar>
            <v-form ref="form" v-model="valid" lazy-validation v-if="showForm">
              <v-card-text>
                <v-container>
                  <v-row class="mt-5">
                    <v-col
                      class="col-lg-6"
                      v-if="editedItem.id && editedItem.situacao !== 0"
                    >
                      <v-btn
                        color="primary"
                        dark
                        class="mr-3"
                        @click="printTermoConformidade"
                      >
                        Termo de conformidade
                        <v-icon dark>mdi-printer </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col
                      class="col-lg-6"
                      v-show="!ocultaBotaoLiberacao"
                      v-if="
                        this.$auth.hasPermission(
                          'liberacao-colheita.reverter-talhao-colhido'
                        ) && editedItem.id &&
                        editedItem.situacao !== 1
                      "
                    >
                      <v-btn
                        color="primary"
                        dark
                        class="mr-3"
                        id="reverteLiberacao"
                        @click="reverteLiberacao"
                      >
                        Reverter Liberação
                        <v-icon dark>mdi-backup-restore </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="editedItem.data_inicial"
                        :value="editedItem.data_inicial"
                        label="Data Inicial"
                        dense
                        outlined
                        type="date"
                        @change="atualizaData"
                        :readonly="editDisabled"
                        :disabled="disabledCamposEdicao"
                        :rules="dataInicioRule"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <EmpresasSelect
                        v-model="editedItem.empresa_id"
                        obrigatorio
                        :readonly="editDisabled"
                        :disabled="disabledCampos"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <ProducoesSelect
                        v-model="editedItem.producao_id"
                        :empresa="editedItem.empresa_id"
                        :readonly="editDisabled"
                        @change="getTalhoes(true)"
                        :disabled="disabledCampos"
                        obrigatorio
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="editedItem.talhao_id"
                        :items="talhoesOptions"
                        label="Talhão"
                        :rules="talhaoRule"
                        item-text="nome"
                        item-value="id"
                        dense
                        outlined
                        :readonly="editDisabled"
                        @change="getInfoFromTalhoes"
                        :disabled="disabledCampos"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="editedItem.cultura_id"
                        :items="culturasOptions"
                        label="Cultura"
                        item-text="nome"
                        item-value="id"
                        dense
                        outlined
                        :readonly="editDisabled"
                        :rules="culturaRule"
                        :disabled="talhaoSelected || disabledCampos"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select
                        v-model="editedItem.variedade_cultura_id"
                        :items="variedadesOptions"
                        label="Variedade"
                        item-text="nome"
                        item-value="id"
                        dense
                        outlined
                        :readonly="editDisabled"
                        :rules="variedadeRule"
                        :disabled="talhaoSelected || disabledCampos"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        ref="area_liberada"
                        v-model="areaLiberada"
                        dense
                        outlined
                        disabled
                        label="Area Liberada m²"
                        reverse
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        ref="area_liberada_ha"
                        v-model="areaLiberadaHa"
                        dense
                        outlined
                        disabled
                        label="Area Liberada Ha"
                        reverse
                      />
                    </v-col>
                  </v-row>
                  <div v-if="editedItem.id">
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-if="this.editedItem.situacao != 1"
                          v-model="editedItem.data_finalizacao"
                          :value="editedItem.data_finalizacao"
                          label="Data Finalização"
                          dense
                          outlined
                          type="date"
                          :readonly="editDisabledDataNotaFiscal"
                          :rules="dataTerminoRule"
                          :max="actualDate"
                          :disabled="disabledCamposEdicaoNotaFiscal"
                          @change="atualizaData"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-textarea
                          v-model="editedItem.observacao"
                          outlined
                          label="Observações"
                          :readonly="editDisabled"
                          :disabled="disabledCamposEdicao"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-radio-group
                          v-model="editedItem.situacao"
                          :readonly="editDisabled"
                          row
                        >
                          <v-radio
                            label="Liberada"
                            color="warning"
                            :value="1"
                            :disabled="editDisabled"
                            @click="setDataFinalizacao(false)"
                          />
                          <v-radio
                            label="Colhida"
                            color="success"
                            :value="2"
                            :disabled="editDisabled"
                            @click="setDataFinalizacaoNota()"
                          />
                          <v-radio
                            label="Cancelada"
                            color="error"
                            :value="0"
                            :disabled="editDisabled"
                            @click="setDataFinalizacao()"
                          />
                          <template v-slot:label>
                            Situação:
                            <custom-label
                              class="ml-2"
                              :color="getColor(situacao)"
                              :text="getSituacao(situacao)"
                            />
                          </template>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </div>
                </v-container>
              </v-card-text>
              <div v-if="!editDisabled">
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close"
                    >Cancelar</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="validate"
                    >Salvar</v-btn
                  >
                </v-card-actions>
              </div>
            </v-form>
            <v-card-text v-else>
              <div class="text-center p-5">
                <h4 class="mb-4">Aguarde...</h4>
                <v-progress-circular
                  :size="100"
                  :width="2"
                  color="primary"
                  indeterminate
                />
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="bg-danger text-white">
            <v-card-title class="headline word-breaker">
              <p>
                Tem certeza que deseja excluir a liberação de colheita
                {{ editedItem.id }}?
              </p>
            </v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-progress-circular
                v-show="loadingDelete"
                :size="50"
                :width="6"
                color="white"
                indeterminate
              />
            </v-card-text>

            <v-card-actions v-show="!loadingDelete">
              <v-spacer />
              <v-btn color="white" text outlined large @click="closeDelete"
                >Não</v-btn
              >
              <v-btn
                color="white"
                text
                outlined
                large
                @click="deleteItemConfirm"
              >
                Sim
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogReverteColheita" max-width="500px">
          <v-card class="bg-danger text-white">
            <v-card-title class="headline word-breaker">
              <p>
                Tem certeza que deseja reverter a liberação <br />de colheita
                {{ editedItem.id }}?
              </p>
            </v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-progress-circular
                v-show="loadingReverteColheita"
                :size="50"
                :width="6"
                color="white"
                indeterminate
              />
            </v-card-text>

            <v-card-actions v-show="!loadingReverteColheita">
              <v-spacer />
              <v-btn
                color="white"
                text
                outlined
                large
                @click="closeReverteColheita"
                >Não</v-btn
              >
              <v-btn
                color="white"
                text
                outlined
                large
                @click="confirmaReverteColheita"
              >
                Sim
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

      <!-- Filtros -->
      <v-row class="mt-3">
        <v-col class="py-0 mb-2 col-12 col-md-4">
          <EmpresasSelect v-model="empresaFilterValue" @input="fieldsSelected"></EmpresasSelect>
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-4">
          <ProducoesSelect v-model="producoesFilterValue" :empresa="empresaFilterValue" multiple return-object @input="fieldsSelected"></ProducoesSelect>
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-4">
          <TalhoesSelect v-model="talhoesFilterValue" :empresa="empresaFilterValue" multiple :producao="producoesFilterValue" @input="fieldsSelected"></TalhoesSelect>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="py-0 mb-2 col-12 col-md-4">
          <v-autocomplete
            v-model="variedadesFilterValue"
            :items="variedadesOptions"
            outlined
            dense
            item-text="nome"
            item-value="id"
            label="Variedade"
            @change="fieldsSelected"
          />
        </v-col>
        <v-col class="py-0 mb-2 col-12 col-md-4">
          <v-select
            v-model="situacaoFilterValue"
            :items="situacaoOptions"
            outlined
            dense
            item-text="text"
            item-value="value"
            label="Situação"
            @change="fieldsSelected"
          />
        </v-col>

        <v-col class="py-0 mb-2 col-12 col-md-4 col-lg-3">
          <v-text-field
            v-model="search"
            outlined
            dense
            label="Pesquisar"
            append-icon="mdi-magnify"
            @keyup="fieldsSelected"
          />
        </v-col>
        <v-col class="py-0 col-12 col-xs-12 col-sm-12 col-lg-1">
          <v-tooltip
            top
            v-model="pendingSearch"
            color="warning"
            max-width="139px"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              max-width="20px"
              outlined
              block
              v-bind="attrs"
              v-on="on"
              color="info"
              class="v-btn--height-fix"
                item-text="nome"
              item-value="id"
              @click="getData"
            >
              <v-icon dark>
                mdi-cached
               </v-icon>
              </v-btn>
              </template>
              <span>Busca pendente</span>
            </v-tooltip>
          </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            dense
            sort-by="data_inicial"
            sort-desc="true"
            :headers="headers"
            :items="data"
            :search="search"
            :items-per-page="$dataTableConfig.getItemsPerPage()"
            :footer-props="$dataTableConfig.getFooterProps()"
            :loading-text="$dataTableConfig.getLoadText()"
            :page="page"
            :page-count="pageCount"
            :options.sync="options"
            :server-items-length="totalItems"
            :loading="loading"
          >
            <template v-slot:progress>
              <v-progress-linear
                absolute
                indeterminate
                color="green"
              ></v-progress-linear>
            </template>

            <template v-slot:[`item.data_inicial`]="{ item }">
              {{ $stringFormat.dateISOToBRL(item.data_inicial, true) }}
            </template>

            <template v-slot:[`item.data_finalizacao`]="{ item }">
              <div
                v-if="
                  item.data_finalizacao === null ||
                  item.data_finalizacao === '1900-01-01'
                "
              >
                Não finalizada
              </div>
              <div v-else>
                {{ $stringFormat.dateISOToBRL(item.data_finalizacao, true) }}
              </div>
            </template>

            <template v-slot:[`item.situacao`]="{ item }">
              <custom-label
                :color="getColor(item.situacao)"
                :text="getSituacao(item.situacao)"
              />
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)"
                >mdi-pencil</v-icon
              >
              <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
            </template>

            <template v-slot:no-data>
              <v-spacer />
              <div>
                <v-row class="justify-center mt-1">
                  <v-col class="col-lg-6">
                    <custom-alert
                      text="Não foi possível obter essas informações"
                    />
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: () => ({
    status: false,
    valid: true,
    menu: false,
    menu2: false,
    menu3: false,
    pendingSearch: false,
    empresaRule: [(v) => !!v || 'Necessário selecionar uma empresa'],
    producaoRule: [(v) => !!v || 'Necessário selecionar uma produção'],
    talhaoRule: [(v) => !!v || 'Necessário selecionar um talhão'],
    culturaRule: [
      (v) => !!v || 'Selecione um talhão para obter a cultura cadastrada',
    ],
    variedadeRule: [
      (v) => !!v || 'Selecione um talhão para obter a variedade cadastrada',
    ],
    dataInicioRule: [(v) => !!v || 'Necessário selecionar a data de inicio'],
    dataTerminoRule: [
      (v) => !!v || 'Necessário selecionar a data de finalização',
    ],
    mask: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 3,
      masked: false,
    },
    empresasOptions: [],
    talhaoSelected: false,
    search: '',
    dialog: false,
    dialogDelete: false,
    situacaoOptions: [
      { text: 'Liberada', value: '1' },
      { text: 'Colhida', value: '2' },
      { text: 'Cancelada', value: '0' },
      { text: 'Todas', value: '' },
    ],
    dialogReverteColheita: false,
    talhoesOptions: [],
    variedadesOptions: [{ nome: 'Todas', id: '' }],
    culturasOptions: [],
    empresasOptionsFilter: [],
    producoesOptionsFilter: [{ id: '', sigla: 'Todas' }],
    producoesOptions: [{ id: '', sigla: 'Necessário escolher uma empresa' }],
    areaTotalHa: '',
    producoesField: [],
    producoesFilterValue: '',
    talhoesFilterValue: '',
    variedadesFilterValue: '',
    situacaoFilterValue: '',
    empresaFilterValue: '',
    data: [],
    editedIndex: -1,
    editedItem: {
      empresa_id: 0,
      producao_id: 0,
      cultura_id: 0,
      talhao_id: 0,
      variedade_cultura_id: 0,
      data_inicial: null,
      data_finalizacao: null,
      area_talhao: 0,
      area_liberada: 0,
      situacao: 1,
      empresa: {},
      producao: {},
      talhao: {},
      cultura: {},
      variedade: {},
    },
    defaultItem: {
      empresa_id: 0,
      producao_id: 0,
      cultura_id: 0,
      talhao_id: 0,
      variedade_cultura_id: 0,
      data_inicial: null,
      data_finalizacao: null,
      area_talhao: 0,
      area_liberada: 0,
      situacao: 1,
      empresa: {},
      producao: {},
      talhao: {},
      cultura: {},
      variedade: {},
    },
    talhao: {
      area_plantada_m2: 0,
      area_plantada_ha: 0,
    },
    editDisabled: false,
    editDisabledDataNotaFiscal: false,
    page: 1,
    pageCount: 0,
    options: {},
    totalItems: 0,
    loading: false,
    loadingDelete: false,
    loadingReverteColheita: false,
    showForm: true,
    areaLiberada: 0,
    areaLiberadaHa: 0,
    disabledCampos: false,
    disabledCamposEdicao: false,
    disabledCamposEdicaoNotaFiscal: false,
    ocultaBotaoLiberacao: false,
  }),
  computed: {
    formTitle () {
      return this.editedIndex !== -1
        ? 'Editar liberação de colheita'
        : 'Adicionar liberação de colheita'
    },
    headers () {
      return [
        {
          text: '#',
          align: 'start',
          value: 'id',
          class: 'custom-table--header',
        },
        {
          text: 'Empresa',
          value: 'empresa',
          class: 'custom-table--header',
        },
        {
          text: 'Produção',
          value: 'producao',
          class: 'custom-table--header',
        },
        { text: 'Talhão', value: 'talhao', class: 'custom-table--header' },
        {
          text: 'Variedade',
          value: 'variedade',
          class: 'custom-table--header',
        },
        {
          text: 'Liberada em',
          value: 'data_inicial',
          class: 'custom-table--header',
        },
        {
          text: 'Finalizada em',
          value: 'data_finalizacao',
          class: 'custom-table--header',
        },
        {
          text: 'Situação',
          value: 'situacao',
          sortable: false,
          class: 'custom-table--header',
        },
        {
          text: 'Ações',
          value: 'actions',
          sortable: false,
          class: 'custom-table--header',
        },
      ]
    },
    actualDate () {
      return this.$date.getISOToday()
    },
    situacao () {
      return parseInt(this.editedItem.situacao)
    },
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    dialogReverteColheita (val) {
      val || this.closeReverteColheita()
    },
    options: {
      handler () {
        this.getData()
      },
    },
  },
  mounted () {
    this.initialize()

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Liberação de colheitas' },
    ])
  },
  methods: {
    initialize () {
      this.getData()

      ApiService.get('/variedade-cultura').then((res) => {
        for (const variedade of res.data.data) {
          this.variedadesOptions.push(variedade)
        }
      })

      ApiService.get('/cultura').then((res) => {
        this.culturasOptions = res.data.data
      })

      this.getEmpresasOptions()

      this.editedItem.data_inicial = this.actualDate
      this.defaultItem.data_inicial = this.actualDate
    },
    fieldsSelected () {
      this.pendingSearch = true
    },
    getData () {
      this.loading = true
      this.pendingSearch = false
      this.data = []
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      const params = {
        page,
        qtde: itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0],
        empresa_id: this.empresaFilterValue,
        producao_id: this.$array.pluck(this.producoesFilterValue, 'id').filter((v) => v !== ''),
        talhao_id: this.talhoesFilterValue,
        variedade_cultura_id: this.variedadesFilterValue,
        situacao: this.situacaoFilterValue,
        search: this.search,
      }

      ApiService.get('/liberacao-colheita', '?' + this.$stringFormat.queryParamsRecursive(params))
        .then((res) => {
          const dados = res.data
          this.loading = false
          this.totalItems = dados.total
          this.pageCount = dados.pageCount
          this.data = dados.data
        })
        .catch((error) => {
          this.loading = false
          console.error('Erro: ', error)
        })
    },

    getProducoesOptions (forFilter) {
      this.producoesOptions = []

      if (forFilter) {
        this.producoesOptionsFilter = [{ sigla: 'Todas' }]
      }

      const empresaId = forFilter
        ? this.empresaFilterValue
        : this.editedItem.empresa_id

      ApiService.get('/producao').then((res) => {
        for (const producao of res.data.data) {
          if (producao.empresa_id === empresaId) {
            const producaoItem = {
              id: producao.id,
              sigla: producao.sigla,
            }

            if (forFilter) {
              this.producoesOptionsFilter.push(producaoItem)
            }

            this.producoesOptions.push(producaoItem)
          }
        }
      })
    },

    getEmpresasOptions () {
      this.empresasOptions = this.$empresas.list()
      this.empresasOptionsFilter = this.$empresas.list(true)
    },

    getColor (boolField) {
      switch (boolField) {
        case 0:
          return 'danger'
        case 1:
          return 'warning'
        case 2:
          return 'success'
      }
    },
    getSituacao (status) {
      switch (status) {
        case 0:
          return 'Cancelada'
        case 1:
          return 'Liberada'
        case 2:
          return 'Colhida'
      }
    },
    async getTalhoes (
      isFormSelect = false,
      isEdit = false,
      talhaoNotFound = false,
    ) {
      if (this.producoesFilterValue !== 'Todas' && !isFormSelect) {
        this.talhoesOptions = [{ nome: 'Todos os talhões', id: '' }]

        const producaoId = this.producoesFilterValue
        const empresaId = this.empresaFilterValue
        const response = await ApiService.query(
          `talhao-producao/${empresaId}/${producaoId}`,
        )

        for (const talhao of response.data.data) {
          this.talhoesOptions.push(talhao)
        }

        // this.getData()
      } else if (isFormSelect && this.editedItem.producao_id !== 0) {
        this.talhoesOptions = [
          {
            id: 0,
            nome: 'Carregando Talhões...',
          },
        ]

        const [year, month] = this.editedItem.data_inicial.split('-')

        let mes = ''

        if (month === '10') {
          mes = 'mes_10'
        } else {
          mes = `mes_${month.replace('0', '')}`
        }

        const params = {
          empresa_id: parseInt(this.editedItem.empresa_id) > 0 ? parseInt(this.editedItem.empresa_id) : null,
          producao_id: parseInt(this.editedItem.producao_id) > 0 ? parseInt(this.editedItem.producao_id) : null,
          ano: year,
          mes,
        }

        try {
          this.talhoesOptions = []

          if (talhaoNotFound && isEdit && parseInt(this.editedItem.talhao_id) === 0) {
            return
          }

          const response =
            talhaoNotFound && isEdit
              ? await ApiService.get(`talhao/${this.editedItem.talhao_id}`)
              : await ApiService.query('talhoes-oferta-grama', { params })

          const talhoes =
            talhaoNotFound && isEdit ? response.data : response.data.data

          if (talhoes.length === 0) {
            this.$refs.snackbar.show(
              'Atenção',
              'Não há talhão disponível para a produção selecionada.',
              'alert',
            )

            return await this.getTalhoes(true, true, true)
          }

          for (const talhao of talhoes) {
            this.talhoesOptions.push(talhao)
          }

          this.getInfoFromTalhoes()
        } catch (error) {
          if (
            Object.prototype.hasOwnProperty.call(error, 'response') &&
            error.response.data.status !== 404 &&
            isEdit !== true
          ) {
            await this.getTalhoes(true, true, true)
          }
        }
      }
    },
    getInfoFromTalhoes () {
      if (this.editedItem.talhao_id) {
        let talhoes = this.talhoesOptions
        talhoes = talhoes.filter((t) => t.id === this.editedItem.talhao_id)

        this.talhao = JSON.stringify(talhoes[0])
        this.talhao = JSON.parse(this.talhao)

        this.editedItem.variedade_cultura_id = parseInt(
          talhoes[0].variedade_cultura_id,
          10,
        )

        this.editedItem.cultura_id = parseInt(talhoes[0].cultura_id, 10)

        this.editedItem.area_liberada = parseFloat(talhoes[0].area_plantada_m2, 10)

        this.areaLiberada =
          this.editedItem.area_liberada.toLocaleString('pt-br')

        this.areaLiberadaHa = this.setAreaTotalH(this.editedItem.area_liberada)

        this.editedItem.area_talhao = parseFloat(talhoes[0].area_plantada_m2, 10)

        this.talhaoSelected = true
      }
    },
    atualizaData () {
      if (this.editedItem.situacao !== 1) {
        this.atualizaDataAtual()
      }

      this.setDataFinalizacaoNota()

      if (this.editedItem.situacao === 0) {
        this.setDataFinalizacao()
      }
    },
    atualizaDataAtual () {
      if (this.editedItem.data_finalizacao === null) {
        this.editedItem.data_finalizacao = this.$date.getISOToday()
      }

      const dataInicial = new Date(this.editedItem.data_inicial)
      const dataFinalizacao = new Date(this.editedItem.data_finalizacao)

      if (dataInicial > dataFinalizacao) {
        this.editedItem.data_inicial = this.$date.getISOToday()
      }
    },
    setDataFinalizacao (addNewDate = true) {
      this.atualizaDataAtual()
      const dataInicial = new Date(this.editedItem.data_inicial)
      const dataFinalizacao = new Date(this.editedItem.data_finalizacao)

      if (dataInicial > dataFinalizacao) {
        this.editedItem.data_finalizacao = this.$date.getISOToday()
      }

      this.ocultaBotaoLiberacao = true
      this.disabledCamposEdicaoNotaFiscal = false
      this.editDisabledDataNotaFiscal = false

      if (!addNewDate) {
        this.editedItem.data_finalizacao = null
      }
    },
    setDataFinalizacaoNota () {
      const params = {
        talhao_id: this.editedItem.talhao_id,
      }

      if (this.editedItem.situacao === 2) {
        this.atualizaDataAtual()
        this.editDisabledDataNotaFiscal = true
        this.disabledCamposEdicaoNotaFiscal = true

        ApiService
          .get('/ultima-data-emitida-nota-fiscal-talhao', '?' + this.$stringFormat.queryParams(params))
          .then((res) => {
            const dataNotaFiscal = res.data.data.data
            const dataInicial = new Date(this.editedItem.data_inicial)
            const dataFinalizacao = new Date(dataNotaFiscal)

            if (dataNotaFiscal) {
              if (dataInicial > dataFinalizacao) {
                this.editedItem.data_finalizacao = this.$date.getISOToday()

                this.$refs.snackbar.show(
                  'Atenção',
                  'Não há nota fiscal emitida para o talhão no período de liberação de colheita.',
                  'alert',
                )
              } else {
                this.editedItem.data_finalizacao = dataNotaFiscal
              }
            }
          }).catch((error) => {
            this.editedItem.data_finalizacao = this.$date.getISOToday()

            this.$refs.snackbar.show(
              'Atenção',
              'Não há nota fiscal emitida para o talhão no período de liberação de colheita.',
              'alert',
            )

            console.error('Erro: ', error)
          })
      }
    },
    empresasFilter (value) {
      if (
        this.empresaFilterValue !== '' ||
        this.empresaFilterValue !== 'Todas'
      ) {
        return true
      }

      return value !== this.empresaFilterValue
    },

    producoesFilter (value) {
      if (
        this.producoesFilterValue !== '' ||
        this.producoesFilterValue !== 'Todas'
      ) {
        return true
      }

      return value !== this.producoesFilterValue
    },
    talhoesFilter (value) {
      if (
        this.talhoesFilterValue !== '' ||
        this.talhoesFilterValue !== 'Todos os talhões'
      ) {
        return true
      }

      return value !== this.talhoesFilterValue
    },
    variedadesFilter (value) {
      if (
        this.variedadesFilterValue !== '' ||
        this.variedadesFilterValue !== 'Todas as variedades'
      ) {
        return true
      }

      return value !== this.variedadesFilterValue
    },
    situacaoFilter (value) {
      if (
        this.situacaoFilterValue !== '' ||
        this.situacaoFilterValue !== 'all'
      ) {
        return true
      }

      return value !== this.situacaoFilterValue
    },
    formatDate (date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    resetAreaInputs () {
      this.talhao.area_plantada_m2 = 0
      this.talhao.area_plantada_ha = 0
    },
    setAreaTotalH (value) {
      let num = parseFloat(value) / 10000
      num = num.toFixed(4)

      num = num.toString().split('.').join(',')

      return num
    },
    validate () {
      if (this.$refs.form.validate()) {
        this.showForm = false

        if (this.editedIndex > -1) {
          const index = this.editedIndex

          ApiService.put(
            `/liberacao-colheita/${this.editedItem.id}`,
            this.editedItem,
          )
            .then((res) => {
              Object.assign(this.data[index], res.data.data)
              this.showForm = true

              this.$refs.snackbar.show(
                'Sucesso!',
                `Liberação de colheita ${this.editedItem.id} atualizada com sucesso`,
              )

              this.getData()
              this.close()
            })
            .catch((error) => {
              this.showForm = true

              this.$refs.snackbar.show(
                'Erro',
                'Não foi possível atualizar a Liberação de colheita',
                'danger',
              )

              console.error('Erro: ', error)
            })
        } else {
          ApiService.post('/liberacao-colheita', this.editedItem)
            .then((res) => {
              this.data.push(res.data.data)
              this.showForm = true

              this.$refs.snackbar.show(
                'Sucesso!',
                `Liberação de colheita ${res.data.data.id} adicionada com sucesso`,
              )

              this.getData()
              this.close()
            })
            .catch((error) => {
              this.showForm = true

              this.$refs.snackbar.show(
                'Erro',
                'Não foi possível adicionar a Liberação de colheita',
                'danger',
              )

              console.error('Erro: ', error)
            })
        }
      }
    },
    async editItem (item) {
      this.disabledCampos = true
      this.disabledCamposEdicao = true
      this.ocultaBotaoLiberacao = false
      this.disabledCamposEdicaoNotaFiscal = true
      this.editDisabledDataNotaFiscal = true
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)

      const talhao = await ApiService.get(`talhao/${item.talhao_id}`)

      this.talhoesOptions = {
        id: talhao.data[0].id,
        nome: talhao.data[0].nome,
      }

      this.editedItem.talhao_id = talhao.data[0].id
      this.editedItem.area_liberada = talhao.data[0].area_plantada_m2

      this.$nextTick(() => {
        this.editDisabled = true

        if (this.editedItem.situacao === 1) {
          this.editedItem.data_finalizacao = null
          this.disabledCamposEdicao = false
          this.editDisabled = false
        }

        this.areaLiberada =
        this.editedItem.area_liberada.toLocaleString('pt-br')

        this.editedItem.area_talhao = parseFloat(this.editedItem.area_liberada, 10)

        this.areaLiberadaHa = this.setAreaTotalH(this.editedItem.area_liberada)
        this.getEmpresasOptions()
        this.getProducoesOptions(false)

        this.dialog = true
      })
    },
    deleteItem (item) {
      this.editedIndex = this.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm () {
      this.loadingDelete = true

      ApiService.delete(`/liberacao-colheita/${this.editedItem.id}`)
        .then(() => {
          this.data.splice(this.editedIndex, 1)

          this.$refs.snackbar.show(
            'Sucesso!',
            `Liberação de colheita ${this.editedItem.id} removida com sucesso`,
          )

          this.loadingDelete = false
          this.closeDelete()
          this.getData()
        })
        .catch((error) => {
          this.loadingDelete = false

          this.$refs.snackbar.show(
            'Erro',
            'Não foi possível remover a Liberação de colheita',
            'danger',
          )

          console.error('Erro: ', error)
          this.closeDelete()
        })
    },
    close () {
      this.disabledCampos = false
      this.talhoesOptions = [{ nome: '', id: '' }]
      this.dialog = false

      this.$nextTick(() => {
        this.$refs.form.resetValidation()
        this.resetAreaInputs()
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.editDisabled = false
        this.areaLiberada = ''
        this.areaLiberadaHa = ''
        this.$refs.form.resetValidation()
      })
    },
    closeDelete () {
      this.dialogDelete = false

      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation()
      })
    },
    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.data[this.editedIndex], this.editedItem)
      } else {
        this.data.push(this.editedItem)
      }

      this.close()
    },
    reverteLiberacao () {
      this.dialogReverteColheita = true
    },
    closeReverteColheita () {
      this.dialogReverteColheita = false
    },
    confirmaReverteColheita () {
      this.ocultaBotaoLiberacao = true
      this.loadingReverteColheita = true
      this.editDisabled = false
      this.loadingReverteColheita = false
      this.dialogReverteColheita = false
      this.disabledCamposEdicao = false
      this.setDataFinalizacaoNota()
      this.atualizaDataAtual()

      if (this.editedItem.situacao === 0) {
        this.setDataFinalizacao()
      }
    },

    async exportFile (tipo) {
      if (this.data.length > 0) {
        const url =
          tipo === 'Excel'
            ? '/relatorios/exportar'
            : '/relatorios/exportar-pdf'
        const filtros = {
          empresa_id:
            parseInt(this.empresaFilterValue) > 0
              ? this.empresaFilterValue
              : '0',
          producao_id:
             this.$array.pluck(this.producoesFilterValue, 'id').filter((v) => v !== '').toString() ? this.$array.pluck(this.producoesFilterValue, 'id').filter((v) => v !== '').toString() : '0',
          talhao_id:
            parseInt(this.talhoesFilterValue) > ''
              ? this.talhoesFilterValue.toString()
              : '0',
          variedade_cultura_id:
            parseInt(this.variedadesFilterValue) > ''
              ? this.variedadesFilterValue
              : '0',
          situacao:
            this.situacaoFilterValue !== '' ? this.situacaoFilterValue : '',
          search: this.search !== '' ? this.search : '0',
        }

        const params = {
          relatorio:
            tipo === 'Excel'
              ? 'LiberacaoColheitas'
              : 'liberacao-colheitas/liberacao-colheitas',
          parametros: JSON.stringify(filtros),
        }

        this.$refs.snackbar.show(
          'Gerando o arquivo',
          'Aguarde alguns segundos para baixar o arquivo',
          'success',
          -1,
          true,
        )

        ApiService.post(url, params)
          .then((res) => {
            if (res.data.data.arquivo !== '') {
              this.$refs.snackbar.show(
                'Arquivo gerado com sucesso',
                '',
                'success',
              )

              window.open(res.data.data.arquivo, '_blank')
            } else {
              this.$refs.snackbar.show(
                'Erro',
                'Não foi possível gerar o arquivo, tente novamente mais tarde.',
                'danger',
              )
            }
          })
          .catch((error) => {
            this.$refs.snackbar.show(
              'Erro',
              'Não foi possível gerar o arquivo, tente novamente mais tarde.',
              'danger',
            )

            console.error('Erro: ', error)
          })
      } else {
        this.$refs.snackbar.show(
          'Erro',
          'Não foram encontrados dados para exportação!',
          'danger',
        )
      }
    },
    async printTermoConformidade () {
      const params = {
        relatorio: 'termo-conformidade/termo-conformidade',
        formato: 'pdf',
        arquivo: `termo_de_conformidade_numero_${this.editedItem.id}${new Date(
          this.editedItem.data_inicial,
        )
          .getFullYear()
          .toString()}`,
        parametros: JSON.stringify({
          liberacao_colheita_id: this.editedItem.id,
        }),
      }

      this.$refs.snackbar.show(
        'Gerando o arquivo',
        'Aguarde alguns segundos para baixar o arquivo',
        'success',
        -1,
        true,
      )

      ApiService.post('/relatorios/exportar-pdf', params)
        .then((res) => {
          if (res.data.data.arquivo !== '') {
            this.$refs.snackbar.show(
              'Arquivo gerado com sucesso',
              '',
              'success',
            )

            window.open(res.data.data.arquivo, '_blank')
          } else {
            this.$refs.snackbar.show(
              'Erro',
              'Não foi possível gerar o arquivo, tente novamente mais tarde.',
              'danger',
            )
          }
        })
        .catch((error) => {
          this.$refs.snackbar.show(
            'Erro',
            'Não foi possível gerar o arquivo, tente novamente mais tarde.',
            'danger',
          )

          console.error('Erro: ', error)
        })
    },
  },
}
</script>
